import set from 'lodash.set';
import { OriginalFile as OriginalFileSecretKeeper, File as FileSecretKeeper } from 'lib/features/secretKeeper/types';
import { OriginalFile as OriginalFileSP, File as FileSP } from 'lib/features/spProvider/types';
import { FileMetaMigrator } from './fileMeta/FileMetaMigrator';

export const transformFileMetadata = (file: OriginalFileSP | OriginalFileSecretKeeper): FileSecretKeeper | FileSP => {
  let transformedMetadata: ReturnType<FileMetaMigrator['getMigratedAppData']>;
  try {
    transformedMetadata = new FileMetaMigrator().getMigratedAppData(file.meta);
  } catch (e) {
    console.error('Transform file metadata error:', e);
  }
  return set(file, 'transformedMetadata', transformedMetadata);
};

export const transformFile = (file: OriginalFileSP | OriginalFileSecretKeeper): FileSecretKeeper | FileSP => {
  transformFileMetadata(file);
  return file;
};