import BigNumber from 'bignumber.js';
import {
  OptionInfo, PriceType, SlotInfo, SlotUsage, TeeOfferSlot, OfferSlot, Offer, TeeOffer, TeeOfferOption,
} from 'generated/types';
import { getMultipliedDeposit, getSumDeposit } from 'utils/sdk/utils';
import {
  getGb, getGbit, getHoursFromMinutes, getMbit, MINUTES_IN_HOURS, round,
} from './math';

export interface OfferSlotUsage {
  minTimeMinutes: number;
  maxTimeMinutes: number;
}

export interface OfferOptionInfo {
  bandwidth: number;
  externalPort: number;
  traffic: number;
}

export interface OfferSlotInfo {
  cpuCores: number;
  gpuCores: number;
  ram: number;
  vram: number;
  diskUsage: number;
}

export type OfferSlotsInfo = OfferSlotInfo & OfferOptionInfo & OfferSlotUsage;

export type OptionData = { label: string; value: string; id: string; }

type Value = string | boolean | number;

type GetOfferSlotValueFormattedCb = (value?: any) => string;
type GetOfferSlotExtenstionCb = (value?: any) => string;

export const offerSlotsInfoList: (keyof OfferSlotsInfo)[] = [
  'cpuCores',
  'gpuCores',
  'ram',
  'vram',
  'diskUsage',
  'bandwidth',
  'externalPort',
  'traffic',
  'maxTimeMinutes',
  'minTimeMinutes',
];

export const offerSlotLabelFormatters = new Map<keyof OfferSlotsInfo, string>([
  ['cpuCores', 'CPU vCores'],
  ['gpuCores', 'GPU vCores'],
  ['ram', 'CPU RAM'],
  ['vram', 'GPU RAM'],
  ['diskUsage', 'Disk'],
  ['bandwidth', 'Bandwidth'],
  ['traffic', 'Traffic'],
  ['externalPort', 'Ext.Port'],
  ['maxTimeMinutes', 'Max Time'],
  ['minTimeMinutes', 'Min Time'],
]);

const offerSlotExtenstion = new Map<keyof OfferSlotsInfo, GetOfferSlotExtenstionCb>([
  ['ram', () => 'GB'],
  ['vram', () => 'GB'],
  ['diskUsage', () => 'GB'],
  ['bandwidth', () => 'Mbps'],
  ['traffic', () => 'Gb'],
  ['maxTimeMinutes', (value?: number) => {
    if (!value) return '';
    return value >= MINUTES_IN_HOURS ? 'hours' : 'minutes';
  }],
  ['minTimeMinutes', (value?: number) => (!value || value >= MINUTES_IN_HOURS ? 'hours' : 'minutes')],
]);

export const getOfferSlotLabelFormatted = (key: keyof OfferSlotsInfo): string => {
  return offerSlotLabelFormatters.get(key) ?? key;
};

export const offerSlotValueFormatters = new Map<keyof OfferSlotsInfo, GetOfferSlotValueFormattedCb>([
  ['externalPort', (value?: boolean) => (value ? 'Yes' : 'No')],
  ['ram', (value?: number) => round(getGb(value))],
  ['vram', (value?: number) => round(getGb(value))],
  ['diskUsage', (value?: number) => round(getGb(value))],
  ['traffic', (value?: number) => round(getGbit(value))],
  ['bandwidth', (value?: number) => round(getMbit(value))],
  ['gpuCores', (value?: number) => round(value)],
  ['cpuCores', (value?: number) => round(value)],
  [
    'maxTimeMinutes',
    (value?: number) => {
      if (!value) return 'Unlimited';
      return round((value >= MINUTES_IN_HOURS) ? getHoursFromMinutes(value) : value);
    },
  ],
  ['minTimeMinutes', (value?: number) => round((!value || value >= MINUTES_IN_HOURS) ? getHoursFromMinutes(value) : value)],
]);

export const getOfferSlotValueFormatted = (key: keyof OfferSlotsInfo, value?: Value): string => {
  return offerSlotValueFormatters.get(key)?.(value) ?? `${value}`;
};

export const getOfferSlotExtension = (key: keyof OfferSlotsInfo, value?: Value): string => {
  return offerSlotExtenstion.get(key)?.(value) ?? '';
};

export const getLabelWithExtenstion = (key: keyof OfferSlotsInfo, value?: Value): string => {
  const label = getOfferSlotLabelFormatted(key);
  const extension = getOfferSlotExtension(key, value);
  const extensionText = extension ? `, ${extension}` : '';
  return `${label}${extensionText}`;
};

export const priceTypeMap = {
  [PriceType.PerHour]: 'Per Hour',
  [PriceType.Fixed]: 'Fixed',
};

export const keysSlot: (keyof OfferSlotInfo)[] = ['cpuCores', 'gpuCores', 'diskUsage', 'ram', 'vram'];
export const keysOptions: (keyof OfferOptionInfo)[] = ['bandwidth', 'traffic', 'externalPort'];
export const keysSlotUsage: (keyof OfferSlotUsage)[] = ['minTimeMinutes', 'maxTimeMinutes'];

export const getEmptySlotInfo = () => {
  return keysSlot.reduce((acc: OptionData[], k) => ([
    ...acc,
    {
      label: getOfferSlotLabelFormatted(k as keyof OfferSlotsInfo),
      value: '-',
      id: k,
    },
  ]), []);
};

export const getEmptyOptionsInfo = () => {
  return keysOptions.reduce((acc: OptionData[], k) => ([
    ...acc,
    {
      label: getOfferSlotLabelFormatted(k as keyof OfferSlotsInfo),
      value: '-',
      id: k,
    },
  ]), []);
};

export const getEmptySlotUsageInfo = () => {
  return keysSlotUsage.reduce((acc: OptionData[], k) => ([
    ...acc,
    {
      label: getOfferSlotLabelFormatted(k as keyof OfferSlotsInfo),
      value: '-',
      id: k,
    },
  ]), []);
};

export interface ConverSlotInfoProps {
  slotInfo?: SlotInfo;
  keys?: (keyof Partial<OfferSlotInfo>)[];
  count?: number;
}

export const convertSlotInfo = (props: ConverSlotInfoProps): OptionData[] => {
  const { slotInfo, keys = keysSlot, count = 1 } = props;
  if (!slotInfo) return [];

  const res = keys.reduce((acc: OptionData[], k) => ([
    ...acc,
    {
      label: getOfferSlotLabelFormatted(k as keyof OfferSlotsInfo),
      value: typeof slotInfo[k] === 'number'
        ? `
          ${getOfferSlotValueFormatted(k as keyof OfferSlotsInfo, slotInfo[k] * count)}
          ${getOfferSlotExtension(k as keyof OfferSlotsInfo, slotInfo[k] * count)}
        `
        : '-',
      id: k,
    },
  ]), []);

  return res;
};

export interface ConvertUsageInfoProps {
  slotUsage?: SlotUsage;
  keys?: (keyof Partial<OfferSlotUsage>)[];
}

export const convertSlotUsageInfo = (props: ConvertUsageInfoProps): OptionData[] => {
  const { slotUsage, keys = keysSlotUsage } = props;
  if (!slotUsage) return [];

  const res = keys.reduce((acc: OptionData[], k) => ([
    ...acc,
    {
      label: getOfferSlotLabelFormatted(k as keyof OfferSlotsInfo),
      value: typeof slotUsage[k] === 'number'
        ? `
          ${getOfferSlotValueFormatted(k as keyof OfferSlotsInfo, slotUsage[k])}
          ${getOfferSlotExtension(k as keyof OfferSlotsInfo, slotUsage[k])}
        `
        : '-',
      id: k,
    },
  ]), []);

  return res;
};

export interface ConvertOptionDataProps {
  optionInfo?: { option: OptionInfo; count: number; }[];
  keys?: (keyof Partial<OfferOptionInfo>)[];
}

export const convertOptionData = (props: ConvertOptionDataProps): OptionData[] => {
  const { optionInfo, keys = keysOptions } = props;
  if (!optionInfo) return [];

  const optionsInfoInit = keys.reduce((acc, item) => ({
    ...acc,
    [item]: 0,
  }), {});

  const optionsSum = optionInfo.reduce((acc, { option, count }) => ({
    ...acc,
    ...Object.entries(option).reduce((accOp, [k, v]) => ({
      ...accOp,
      [k]: acc[k] + ((v || 0) as number) * count,
    }), {}),
  }), optionsInfoInit);

  const res = Object.entries(optionsSum).reduce((acc: OptionData[], [k, v]) => ([
    ...acc,
    {
      label: getOfferSlotLabelFormatted(k as keyof OfferSlotsInfo),
      value: v !== undefined
        // eslint-disable-next-line max-len
        ? `${getOfferSlotValueFormatted(k as keyof OfferSlotsInfo, v as number)} ${getOfferSlotExtension(k as keyof OfferSlotsInfo, v as number)}`
        : '-',
      id: k,
    },
  ]), []);

  return res;
};

const valueMapDefault: { [key: string]: number } = {
  cpuCores: 0,
  ram: 1,
  gpuCores: 2,
  vram: 3,
  diskUsage: 4,
  bandwidth: 5,
  traffic: 6,
  externalPort: 7,
  minTimeMinutes: 8,
  maxTimeMinutes: 9,
};

export const sortOptionsById = (options: OptionData[], valueMap = valueMapDefault): OptionData[] => (
  options.sort((a, b) => valueMap[a.id as string] - valueMap[b.id as string])
);

export interface Deposit {
  perHour: BigNumber | null;
  fixed: BigNumber | null;
  perHourByLease: BigNumber | null; // multiply by lease with minTimeMinutes check
}

export interface GetDepositProps {
  slotUsage?: SlotUsage;
  count?: number;
  deposit?: Deposit;
  lease?: number;
  minTimeMinutesTEE?: number;
}

export const getDeposit = (props: GetDepositProps): Deposit => {
  const {
    slotUsage,
    count,
    deposit = { perHour: null, fixed: null, perHourByLease: null },
    lease = 0,
    minTimeMinutesTEE = 0, // does not need for options
  } = props || {};
  const newDeposit = { ...deposit };
  if (!slotUsage) return newDeposit;
  const { minTimeMinutes, priceType, price } = slotUsage || {};
  const MINUTES_IN_HOUR = 60;
  const globalMinRentMinutes = Math.max(lease * 60, minTimeMinutes || minTimeMinutesTEE) || MINUTES_IN_HOUR;
  if (priceType === PriceType.PerHour) {
    newDeposit.perHourByLease = count && price
      ? getSumDeposit(newDeposit.perHourByLease, getMultipliedDeposit(price, (count * globalMinRentMinutes) / 60))
      : newDeposit.perHour;
    newDeposit.perHour = count && price
      ? getSumDeposit(newDeposit.perHour, getMultipliedDeposit(price, count))
      : newDeposit.perHour;
  } else if (priceType === PriceType.Fixed) {
    newDeposit.fixed = count && price
      ? getSumDeposit(newDeposit.fixed, getMultipliedDeposit(price, count))
      : newDeposit.fixed;
  }
  return newDeposit;
};

export const getMinPriceFromSlots = (slots: (TeeOfferSlot | OfferSlot)[], priceType: PriceType): string | null => {
  if (!slots?.length) return null;
  if (!priceType) return null;
  return slots
    .filter(({ usage }) => usage?.priceType === priceType)
    .map(({ usage }) => usage?.price || null)
    .reduce((acc, price) => {
      if (typeof price === 'string') {
        return typeof acc === 'string' ? BigNumber.minimum(acc || '0', price).toString() : price;
      }
      return acc;
    }, null);
};

export const getMultipleDeposits = (deposits: (Deposit | null)[]): Deposit | null => {
  if (!deposits?.length) return null;
  return deposits.reduce((acc, item) => {
    if (!item) return acc;
    const { fixed, perHour, perHourByLease } = item;
    return {
      fixed: getSumDeposit(acc?.fixed || null, fixed),
      perHour: getSumDeposit(acc?.perHour || null, perHour),
      perHourByLease: getSumDeposit(acc?.perHourByLease || null, perHourByLease),
    };
  }, null);
};

export const getSlotFromOffer = (offer?: Offer | null, slotId?: string | null): OfferSlot | null => {
  if (!offer || !slotId) return null;
  const { slots } = offer;
  if (!slots?.length) return null;
  return slots.find(({ id }) => slotId === id) || null;
};

export const getSlotFromTEEOffer = (teeOffer?: TeeOffer | null, slotId?: string | null): TeeOfferSlot | null => {
  if (!teeOffer || !slotId) return null;
  const { slots } = teeOffer;
  if (!slots?.length) return null;
  return slots.find(({ id }) => slotId === id) || null;
};

export const getOptionsFromTEEOffer = (teeOffer: TeeOffer, optionsIds: string[]): TeeOfferOption[] => {
  if (!teeOffer || !optionsIds?.length) return [];
  const { options } = teeOffer;
  if (!options?.length) return [];
  return options.filter(({ id }) => optionsIds.includes(id));
};


export const getSortedSlots = <T extends OfferSlot | TeeOfferSlot>(slots: T[]): T[] => {
  const sortedByPrice = [...slots].sort((a, b) => {
    const aBN = new BigNumber(a.usage.price);
    return aBN.comparedTo(new BigNumber(b.usage.price));
  });
  return sortedByPrice.sort((a, b) => {
    if (a.info.vram > 0 || b.info.vram > 0) {
      return b.info.vram - a.info.vram;
    }
    if (a.info.ram > 0 || b.info.ram > 0) {
      return b.info.ram - a.info.ram;
    }
    return 0;
  });
};
