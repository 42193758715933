import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import { v4 as uuid } from 'uuid';

import { Storage } from '../types';
import { AnalyticsState } from './types';

const initialState: AnalyticsState = {
  fp: uuid(),
};

export const analytics = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    setFP(state, action: PayloadAction<string>) {
      state.fp = action.payload;
    },
  },
  selectors: {
    fpSelector: (state) => state.fp,
  },
});

export const {
  setFP,
} = analytics.actions;

export const {
  fpSelector,
} = analytics.selectors;

export const getReducer = (storage: Storage) => persistReducer({
  key: 'analytics', storage, whitelist: ['fp'],
}, analytics.reducer);
