import * as Yup from 'yup';
import { OfferAttributes } from '@super-protocol/dto-js';

import { Option } from 'uikit/Select/types';
import { ElementStruct } from './Elements/types';
import { getFlatData } from './utils';

const { SupportedArgumentType: Type, SupportedValueType } = OfferAttributes;

export const getDefaultNameValue = (
  type?: OfferAttributes.SupportedArgumentType,
  options?: OfferAttributes.VariantValue[],
): string | undefined => {
  switch (type) {
    case Type.NUMBER:
    case Type.SLIDER:
      return mapTypes[SupportedValueType.INT32];
    case Type.TEXT:
    case Type.TEXTAREA:
    case Type.RADIO:
      return mapTypes[SupportedValueType.STRING];
    case Type.CHECKBOX:
      return mapTypes[SupportedValueType.BOOL];
    case Type.SELECT:
    case Type.MULTISELECT:
    case Type.ORDER:
      return options?.length ? Object.keys(options[0])[0] : undefined;
    default:
      return undefined;
  }
};

export const defaultResult = {
  struct: {},
  errors: [],
  fdata: {},
};

const mapTypes = {
  [SupportedValueType.DOUBLE]: 'doubleValue',
  [SupportedValueType.FLOAT]: 'floatValue',
  [SupportedValueType.INT32]: 'int32Value',
  [SupportedValueType.INT64]: 'int64Value',
  [SupportedValueType.UINT32]: 'uint32Value',
  [SupportedValueType.UINT64]: 'uint64Value',
  [SupportedValueType.SINT32]: 'sint32Value',
  [SupportedValueType.SINT64]: 'sint64Value',
  [SupportedValueType.FIXED32]: 'fixed32Value',
  [SupportedValueType.FIXED64]: 'fixed64Value',
  [SupportedValueType.SFIXED32]: 'sfixed32Value',
  [SupportedValueType.SFIXED64]: 'sfixed64Value',
  [SupportedValueType.BOOL]: 'boolValue',
  [SupportedValueType.STRING]: 'stringValue',
  [SupportedValueType.BYTES]: 'bytesValue',
};

export const getNameValueByValueType = (valueType?: OfferAttributes.SupportedValueType) => {
  if (!valueType) return undefined;
  return mapTypes[valueType];
};

const getDefaultValue = (
  defaultValue?: OfferAttributes.VariantValue[],
  type?: OfferAttributes.SupportedArgumentType,
  options?: OfferAttributes.VariantValue[],
  valueType?: OfferAttributes.SupportedValueType,
) => {
  const nameValue: string | undefined = getNameValueByValueType(valueType) || getDefaultNameValue(type, options);
  if (!nameValue) return undefined;
  const res = defaultValue?.[0]?.[nameValue];
  switch (type) {
    case Type.SELECT:
    case Type.ORDER:
      return { value: res, label: res };
    case Type.MULTISELECT:
      return defaultValue?.map((item) => {
        const value = item?.[nameValue];
        return { value, label: value };
      });
    default:
      return res;
  }
};

const getDefaultInitValue = (
  value?: string | number,
  defaultValue?: OfferAttributes.VariantValue[],
  type?: OfferAttributes.SupportedArgumentType,
  options?: OfferAttributes.VariantValue[],
  valueType?: OfferAttributes.SupportedValueType,
) => {
  const nameValue: string | undefined = getNameValueByValueType(valueType) || getDefaultNameValue(type, options);
  if (!nameValue) return undefined;
  switch (type) {
    case Type.SELECT:
    case Type.ORDER:
      return { value, label: value };
    case Type.MULTISELECT:
      return defaultValue?.map((item) => {
        const value = item?.[nameValue];
        return { value, label: value };
      });
    default:
      return value;
  }
};

export const getInitialValues = (
  fdata: Record<string, OfferAttributes.ArgumentType>,
  configuration?: Record<string, any> | null,
) => {
  let flatConfiguration = {};
  if (configuration) {
    flatConfiguration = getFlatData(configuration);
  }
  return (Object.entries(fdata) || []).reduce((acc, [k, v]) => {
    const {
      defaultValue, options, type, valueType,
    } = v;
    const initValue = flatConfiguration[k];
    return (
      {
        ...acc,
        [k]: initValue !== undefined
          ? getDefaultInitValue(initValue, defaultValue, type, options, valueType)
          : getDefaultValue(defaultValue, type, options, valueType),
      }
    );
  }, {});
};

export const getSchema = (field: string, validateRegexp: string): Yup.BaseSchema => Yup.string().test(
  field,
  'Invalid field',
  // eslint-disable-next-line func-names, prefer-arrow-callback
  function (value) {
    const re = new RegExp(validateRegexp);
    return re.test(value as string);
  },
) as Yup.BaseSchema;

export const getValidationSchema = (fdata: Record<string, OfferAttributes.ArgumentType>) => {
  const validationSchema = (Object.entries(fdata) || []).reduce((acc, [k, v]) => {
    const { validateRegexp } = v;
    return (validateRegexp
      ? {
        ...acc,
        [k]: getSchema(k, validateRegexp),
      } : acc);
  }, {} as Record<string, Yup.BaseSchema>);
  return Yup.object(validationSchema);
};

const transformValue = (type: OfferAttributes.SupportedArgumentType, value: string | boolean | number | Option | Option[]) => {
  switch (type) {
    case Type.SELECT:
    case Type.ORDER:
      return (value as Option).value;
    case Type.MULTISELECT:
      return (value as Option[]).map((item) => item.value);
    default:
      return value;
  }
};

export const getFormattedElements = (
  values: OfferAttributes.ArgumentType,
  struct: Record<string, ElementStruct>,
  conditions: Record<string, boolean>,
) => {
  return Object.entries((struct)).reduce((acc, [k, v]) => {
    const { children, path, type } = v;
    if (conditions[path] === false) return acc;

    return ({
      ...acc,
      [k]: children && Object.keys(children).length
        ? getFormattedElements(values, children, conditions)
        : transformValue(type as OfferAttributes.SupportedArgumentType, values[path]),
    });
  }, {});
};
