import type {
  EncryptionWithIV, StorageType, OfferAttributes,
} from '@super-protocol/dto-js';
import type {
  ValueOfferSubtype,
  ProviderInfo,
  SlotUsage,
  SlotInfo,
  OptionInfo,
  OfferVersionInfo,
} from '@super-protocol/sdk-js';
import { OfferInfo } from '@super-protocol/sdk-js';
import { FileMetaMigrator } from 'utils/providers/files/fileMeta/FileMetaMigrator';

export interface User {
  id: string;
  createdAt: string;
  updatedAt: string;
  address?: string;
  nonce?: string;
}

export enum Providers {
  metamask = 'metamask',
}

export interface AccessToken { address?: string; provider?: Providers; id?: string }

export interface SecretKeeperState {
  user?: User | null;
  accessToken?: string | null;
  loadingAuthWallet: boolean;
}

export interface TokenResponse { user: User, accessToken: string }

export type Language = (typeof OfferAttributes.LanguageCodeEnum)[keyof typeof OfferAttributes.LanguageCodeEnum];

export interface OriginalFile {
  id: string;
  storageId: string;
  userId: string;
  path: string;
  encryption: EncryptionWithIV;
  name: string;
  offerId?: string;
  description: string;
  type: ValueOfferSubtype;
  createdAt: string;
  updatedAt: string;
  pipelineTypes: OfferAttributes.PipelineType[];
  engines: string[];
  license?: OfferAttributes.LicenseCode,
  libraries?: OfferAttributes.SupportedLibrary[],
  languages?: Language[];
  meta?: Record<string, unknown>;
}

export interface File extends OriginalFile {
  transformedMetadata?: ReturnType<FileMetaMigrator['getData']>;
}

export interface PostFileProps extends Omit<File, 'id' | 'userId' | 'createdAt' | 'updatedAt' | 'path'> {}

export interface PatchFileBodyProps extends Omit<Partial<File>, 'id' | 'userId' | 'createdAt' | 'updatedAt'> {}

export interface PostFileResult {
  id: string;
}

export interface UpdateFileProps {
  body: PatchFileBodyProps;
  id: string;
}

export interface StorjStorageCredentials {
  readAccessToken: string;
  writeAccessToken: string;
}

export interface S3StorageCredentials {
  region?: string;
  writeAccessKeyId: string;
  writeSecretAccessKey: string;
  readAccessKeyId: string;
  readSecretAccessKey: string;
}

export interface Storage {
  id: string;
  isCentralized: boolean;
  userId: string;
  storageType: StorageType;
  bucket: string;
  prefix: string;
  storjCredentials?: StorjStorageCredentials;
  s3Credentials?: S3StorageCredentials;
  createdAt: string;
  updatedAt: string;
}

export interface UserSettings {
  activeStorageId?: string;
  decentralizedStorageId?: string;
}

export type PostStorage = Omit<Storage, 'id' | 'updatedAt' | 'createdAt' | 'isCentralized' | 'userId'>;

export interface GetFilesFilter {
  type?: ValueOfferSubtype,
  pipelineType?: OfferAttributes.PipelineType;
}

export interface GetFilesProps {
  filter?: GetFilesFilter;
}

export interface OrderMeta {
  domain?: string;
  wasAvailable?: boolean;
  model?: { id: string; name: string; },
}

export interface CreateOrderResponse {
  linkedOrders: string[];
  meta: OrderMeta;
  id: string;
  privateKey: string;
  userId: string;
}

export interface Order {
  linkedOrders: string[];
  meta: OrderMeta;
  id: string;
  privateKey: string;
  orderId: string;
  userId: string;
  createdAt: string;
  updatedAt: string;
}

export interface CreateOrderRequest extends Partial<Order> {
  id: Order['id'];
  privateKey: Order['privateKey'];
}

export interface PatchOrderResponse extends Order {}

export interface PatchOrderRequest {
  body: Partial<Omit<Order, 'id' | 'createdAt' | 'updatedAt' | 'privateKey'>>;
  id: string;
}

export interface GetOrderResponse extends Order {}
export interface GetOrderListResponse extends Order {}

export interface GetOrdersRequest {
  filter: { id: string; };
}

export interface GetOrdersListRequest {}

export interface Provider {
  id: string;
  userId: string;
  actionAccount?: string;
  authorityAddress?: string;
  tokenReceiverAccount?: string;
  authorityPrivateKey?: string;
  tokenReceiverPrivateKey?: string;
  actionPrivateKey?: string;
  createdAt: string;
  updatedAt: string;
}

export interface ProviderRequest extends Omit<ProviderInfo, 'tokenReceiver' | 'actionAccount'> {
  authorityPrivateKey?: string;
  tokenReceiverPrivateKey?: string;
  actionPrivateKey?: string;
}

export type CreateProviderRequest = Omit<ProviderRequest, 'id' | 'userId'>;

export interface UpdateProviderRequest {
  body: Partial<CreateProviderRequest>;
  id: string;
}

export interface Slot {
  id: string;
  path?: string;
  createdAt: string;
  updatedAt: string;
}

export interface Offer {
  id: string;
  userId: string;
  providerId: string;
  createdAt: string;
  updatedAt: string;
  content: File | null;
  slots: Slot[];
}

export type CreateOfferRequest = {
  offerInfo: Omit<OfferInfo, 'argsPublicKey'>;
  contentId: string;
  versionInfo: OfferVersionInfo;
};

export interface UpdateOfferRequest {
  body: {
    offerInfo: Partial<CreateOfferRequest['offerInfo']>;
  };
  id: string;
}

export interface PublishOfferRequest {
  body: {
    versionInfo: OfferVersionInfo;
  };
  id: string;
}

export interface CreateSlotBody {
  info: SlotInfo;
  usage: SlotUsage;
  option: OptionInfo;
  path: string,
  metadata: Record<string, unknown>;
}

export interface CreateSlotRequest {
  offerId: string;
  body: CreateSlotBody;
}

export interface UpdateSlotRequest {
  offerId: string;
  body: CreateSlotBody;
  slotId: string;
}

export interface DeleteSlotRequest {
  offerId: string;
  slotId: string;
}