import { createAsyncThunk, Dispatch } from '@reduxjs/toolkit';

import { addSuccessNotification, addErrorNotification } from 'lib/features/notifications';
import { trackEvent } from 'lib/features/events/thunks';
import { validateRTKResponse } from 'lib/features/helpers';
import { successNotification } from '../sdk/helpers';
import { RTKResponse } from '../types';

export const refillTEE = createAsyncThunk<
void,
  { transfer:(
    arg: null) => Promise<RTKResponse<unknown>> },
  { rejectValue: string | Error, dispatch: Dispatch<any> }
    >(
    'spProvider/refillTEE',
    async ({ transfer }, { rejectWithValue, dispatch }) => {
      try {
        validateRTKResponse(await transfer(null));
        dispatch(addSuccessNotification(successNotification));
        dispatch(trackEvent({ eventType: 'get_tee', property: { result: 'success' } }));
      } catch (e) {
        const message = (e as Error)?.message || 'Unknown error';
        try {
          const parsedMessage = typeof message === 'string' ? JSON.parse(message)?.message : message;
          dispatch(addErrorNotification(parsedMessage || 'Unknown error'));
        } catch {
          dispatch(addErrorNotification(message));
        }
        dispatch(trackEvent({
          eventType: 'get_tee',
          property: { result: 'error', error: message, errorStack: (e as Error)?.stack },
        }));
        return rejectWithValue(message);
      }
    },
    );
