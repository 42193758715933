import { OfferAttributes } from '@super-protocol/dto-js';
import { ElementStruct } from './Elements/types';

const { SupportedArgumentType: Type } = OfferAttributes;

export type GetValuesByTypeProps = {
  data: Record<string, any>;
  schema: OfferAttributes.ArgumentType[];
  type: OfferAttributes.SupportedArgumentType;
}

export const isGroup = (type: OfferAttributes.SupportedArgumentType) => (
  [Type.CATEGORY, Type.SUBGROUP, Type.GROUP].includes(type)
);

const isError = (type: OfferAttributes.SupportedArgumentType, level: number) => {
  if (type === Type.CATEGORY && level > 0) return true;
  if (type !== Type.CATEGORY && level === 0) return true;
  if (type === Type.GROUP && level !== 1) return true;
  if (type === Type.SUBGROUP && level !== 2) return true;
  if (!isGroup(type) && level === 0) return true;
  return false;
};

export const createStructure = (data: OfferAttributes.ArgumentType[], path?: string, level = 0):
{
  struct: Record<string, ElementStruct>,
  errors: OfferAttributes.ArgumentType[],
  fdata: Record<string, OfferAttributes.ArgumentType>,
} => {
  let errors: OfferAttributes.ArgumentType[] = [];
  let fdata = {};
  const res = {
    struct: data.reduce((acc: any, item) => {
      const {
        variable, type, children, name,
      } = item;
      if (!variable || typeof type !== 'number') return acc;
      const currPath = path ? `${path}:${variable as string}` : variable as string;
      const hasError = isError(type, level);
      if (hasError) {
        errors.push(item);
        return acc;
      }
      const elements = children ? createStructure(children, currPath, level + 1) : children;
      fdata = { ...fdata, [currPath]: item };
      errors = elements ? [...errors, ...elements.errors] : errors;
      fdata = elements ? { ...fdata, ...elements.fdata } : fdata;
      const defaultElement = {
        [variable]: {
          variable,
          type,
          name,
          path: currPath,
          children: elements ? elements.struct : children,
        },
      };
      return {
        ...acc,
        ...defaultElement,
      };
    }, {}),
    errors,
    fdata,
  };
  return res;
};

export const getFlatData = (data: Record<string, any>, path = '') => {
  return (Object.entries(data) || []).reduce((acc, [k, v]) => {
    const currPath = path ? `${path}:${k}` : k;
    const flatData = typeof v === 'object' ? getFlatData(v, currPath) : {};
    return (
      { ...acc, ...(typeof v === 'object' ? {} : { [currPath]: v }), ...flatData }
    );
  }, {});
};

export const getValuesByType = ({
  data,
  schema,
  type,
}: GetValuesByTypeProps) => {
  const flatData = getFlatData(data);
  const { fdata } = createStructure(schema);
  const namesByType = Object.entries(fdata).filter(([, v]) => v.type === type).map(([k]) => k);

  return namesByType.map((item) => (
    { [item.replace(/:/g, '.')]: flatData[item] }
  ));
};
