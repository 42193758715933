import * as yup from 'yup';
import clonedeep from 'lodash.clonedeep';

export type Data<TVersions> = { version: keyof TVersions, data: TVersions[keyof TVersions] };

export abstract class Migrator<T> {
  protected abstract get latestVersion(): number;
  protected abstract get schemas(): Record<number, yup.SchemaOf<T[keyof T]>>;
  // example:
  // 1: (data: T1): T2 => ({
  //   version: 2,
  //   newField: data.previousField,
  //   details: { info: 'default' },
  // }),
  protected abstract get migrations(): Record<number, (data: Data<T>) => Data<T>>;

  protected initializeData(meta?: Partial<Data<T>>): Data<T> {
    if (meta && typeof meta.version === 'number') {
      return meta as Data<T>;
    }

    if (!this.schemas[this.latestVersion]) {
      throw new Error('Initial schema is required!');
    }

    return this.schemas[this.latestVersion].cast({ version: this.latestVersion } as Partial<Data<T>>) as Data<T>;
  }

  protected validate(data: unknown) {
    const finalSchema = this.schemas[this.latestVersion];
    return finalSchema.validateSync(data, { stripUnknown: true, abortEarly: false });
  }

  protected migrate(meta: Data<T>): Data<T> {
    let initializedData = clonedeep(this.initializeData(meta));
    if (!initializedData.version || typeof initializedData.version !== 'number') {
      throw new Error('version required!');
    }

    let { version } = initializedData;

    while (version < this.latestVersion) {
      const migrateFn = this.migrations[version];
      if (!migrateFn) break;
      initializedData = migrateFn(initializedData);
      version++;
    }

    return {
      version: this.latestVersion,
      data: this.validate(initializedData?.data),
    } as Data<T>;
  }
}