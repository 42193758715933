import getConfig from 'config';
import mergeWith from 'lodash.mergewith';
import { DeepPartial } from 'types/types';
import {
  PostStorage,
  S3StorageCredentials,
  Storage,
} from 'hooks/storage/types';
import { S3Credentials, StorageCredentials } from '@super-protocol/dto-js';
import {
  UploadFileStreamS3, UploadFileS3, DeleteFileS3, DownloadFileStreamS3,
} from './S3';

export interface GetWriteS3ConfigStorage extends Omit<PostStorage, 's3Credentials'> {
  s3Credentials?: Pick<S3StorageCredentials, 'region' | 'writeAccessKeyId' | 'writeSecretAccessKey'>;
}

export const getWriteS3Config = (
  storage?: GetWriteS3ConfigStorage,
  config?: DeepPartial<DeleteFileS3 | UploadFileStreamS3 | UploadFileS3>,
): DeleteFileS3 | UploadFileStreamS3 | UploadFileS3 => {
  if (!storage) throw new Error('Storage required');
  const {
    NEXT_PUBLIC_S3_API, NEXT_PUBLIC_S3_REGION,
  } = getConfig();
  const { s3Credentials, bucket, prefix } = storage || {};
  const { writeAccessKeyId, writeSecretAccessKey, region } = s3Credentials || {};
  if (!writeAccessKeyId) throw new Error('Write access key id required');
  if (!writeSecretAccessKey) throw new Error('Write secret access key required');
  if (!bucket) throw new Error('bucket required');
  const savedRegion = region || NEXT_PUBLIC_S3_REGION;
  if (!savedRegion) throw new Error('reqion required');
  const baseConfig = {
    clientConfig: {
      region: savedRegion,
      endpoint: NEXT_PUBLIC_S3_API,
      credentials: {
        accessKeyId: writeAccessKeyId,
        secretAccessKey: writeSecretAccessKey,
      },
      forcePathStyle: true,
    },
    params: {
      Bucket: bucket,
    },
    prefix,
  };
  if (config) {
    return mergeWith(baseConfig, config);
  }
  return baseConfig;
};

export interface GetReadS3ConfigStorage extends Omit<PostStorage, 's3Credentials'> {
  s3Credentials?: Pick<S3StorageCredentials, 'region' | 'readAccessKeyId' | 'readSecretAccessKey'>;
}

export const getReadS3Config = (
  storage?: GetReadS3ConfigStorage,
  config?: DeepPartial<DownloadFileStreamS3>,
): DownloadFileStreamS3 => {
  if (!storage) throw new Error('Storage required');
  const {
    NEXT_PUBLIC_S3_API, NEXT_PUBLIC_S3_REGION,
  } = getConfig();
  const { s3Credentials, bucket, prefix } = storage || {};
  const { readAccessKeyId, readSecretAccessKey, region } = s3Credentials || {};
  if (!readAccessKeyId) throw new Error('Read access key id required');
  if (!readSecretAccessKey) throw new Error('Read secret access key required');
  if (!bucket) throw new Error('bucket required');
  const savedRegion = region || NEXT_PUBLIC_S3_REGION;
  if (!savedRegion) throw new Error('reqion required');
  const baseConfig = {
    clientConfig: {
      region: savedRegion,
      endpoint: NEXT_PUBLIC_S3_API,
      credentials: {
        accessKeyId: readAccessKeyId,
        secretAccessKey: readSecretAccessKey,
      },
      forcePathStyle: true,
    },
    params: {
      Bucket: bucket,
    },
    prefix,
  };
  if (config) {
    return mergeWith(baseConfig, config);
  }
  return baseConfig;
};

export const getS3ConfigFromCredentials = (
  credentials: S3Credentials,
  config?: DeepPartial<DownloadFileStreamS3> | DeepPartial<DeleteFileS3 | UploadFileStreamS3 | UploadFileS3>,
): DownloadFileStreamS3 => {
  const {
    bucket, prefix, accessKeyId, secretKey, endpoint,
  } = credentials;
  const {
    NEXT_PUBLIC_S3_REGION,
  } = getConfig();

  const baseConfig = {
    clientConfig: {
      region: NEXT_PUBLIC_S3_REGION,
      endpoint,
      credentials: {
        accessKeyId,
        secretAccessKey: secretKey,
      },
      forcePathStyle: true,
    },
    params: {
      Bucket: bucket,
    },
    prefix,
  };

  if (config) {
    return mergeWith(baseConfig, config);
  }
  return baseConfig;
};

export const getStorageS3CredentialsFromStorage = (storage: Storage): StorageCredentials<S3Credentials> => {
  if (!storage?.s3Credentials) {
    throw new Error('S3 credentials required');
  }
  const {
    NEXT_PUBLIC_S3_API,
  } = getConfig();
  return {
    storageType: storage.storageType,
    uploadCredentials: {
      accessKeyId: storage.s3Credentials.writeAccessKeyId,
      secretKey: storage.s3Credentials.writeSecretAccessKey,
      endpoint: NEXT_PUBLIC_S3_API,
      bucket: storage.bucket,
      prefix: storage.prefix,
    },
    downloadCredentials: {
      accessKeyId: storage.s3Credentials.readAccessKeyId,
      secretKey: storage.s3Credentials.readSecretAccessKey,
      endpoint: NEXT_PUBLIC_S3_API,
      bucket: storage.bucket,
      prefix: storage.prefix,
    },
  };
};