export const MINUTES_IN_HOURS = 60;

export const getHoursFromMinutes = (value?: number): number => {
  return (value ? value / MINUTES_IN_HOURS : 0);
};

export const getMinutesFromHours = (value?: number): number => {
  return (value ? value * MINUTES_IN_HOURS : 0);
};

export const getGb = (value?: number): number => {
  const COUNT = 1024;
  return (value ? value / (COUNT * COUNT * COUNT) : 0);
};

export const toGB = (value?: number): number => {
  const COUNT = 1024;
  return (value ? value * (COUNT * COUNT * COUNT) : 0);
};

export const getBytesFromGb = (value?: number): number => {
  const COUNT = 1024;
  return (value ? value * (COUNT * COUNT * COUNT) : 0);
};

export const getGbit = (value?: number): number => {
  const COUNT = 1000;
  return (value ? value / (COUNT * COUNT * COUNT) : 0);
};

export const toGbit = (value?: number): number => {
  const COUNT = 1000;
  return (value ? value * (COUNT * COUNT * COUNT) : 0);
};

export const getBytesFromGbit = (value?: number): number => {
  const COUNT = 1000;
  return (value ? value * (COUNT * COUNT * COUNT) : 0);
};

export const getMbit = (value?: number): number => {
  const COUNT = 1000;
  return (value ? value / (COUNT * COUNT) : 0);
};

export const toMbit = (value?: number): number => {
  const COUNT = 1000;
  return (value ? value * (COUNT * COUNT) : 0);
};

export const getBytesFromMbit = (value?: number): number => {
  const COUNT = 1000;
  return (value ? value * (COUNT * COUNT) : 0);
};

export const round = (value?: number): string => {
  const FIXED_COUNT = 3;
  const factor = 10 ** FIXED_COUNT;
  return (value ? `${parseFloat((Math.ceil(value * factor) / factor).toFixed(FIXED_COUNT))}` : '0');
};
