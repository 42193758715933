import { OfferAttributes } from '@super-protocol/dto-js';

import { Option } from 'uikitv2/Select/types';
import {
  getDefaultNameValue, getNameValueByValueType, getValidationSchema,
} from '../helpers';
import { isGroup } from '../utils';
import { Condition, ElementStruct, GroupOption } from './types';

const { SupportedArgumentType: Type, SupportedValueType } = OfferAttributes;

export const getOptions = (options?: OfferAttributes.VariantValue[] | null) => {
  if (!options) return [];
  const key = getDefaultNameValue(Type.SELECT, options);
  return options.map((item) => {
    const value = key ? item[key] : '';
    return {
      value,
      label: value,
    };
  });
};

const getFormattedConditions = (
  fdata: Record<string, OfferAttributes.ArgumentType>,
  condition?: OfferAttributes.UseCondition | null,
): Condition | undefined => {
  const { variable, value, matchRegexp } = condition || {};
  if (!variable) return undefined;
  const field = variable.split('.').join(':');
  const element = fdata[field];
  if (!element) return undefined;
  const { type, options, valueType } = element;
  const nameValue = getNameValueByValueType(valueType) || getDefaultNameValue(type, options);
  return {
    field,
    value: value && nameValue ? value[nameValue] : undefined,
    matchRegexp,
  };
};

const getExamineValueByType = (
  type?: OfferAttributes.SupportedArgumentType | null,
  valueField?: string | number | boolean | Option | Option[],
  value?: string | number | boolean,
) => {
  switch (type) {
    case Type.SELECT:
    case Type.ORDER:
      return value === (valueField as Option)?.value;
    case Type.MULTISELECT:
      return (valueField as Option[] || []).map((item) => item.value).includes(value as string | number);
    default:
      return value === valueField;
  }
};

const getMatchRegexpByType = (
  type?: OfferAttributes.SupportedArgumentType | null,
  valueField?: string | number | boolean | Option | Option[],
  matchRegexp?: string,
) => {
  if (!matchRegexp) return false;
  const re = new RegExp(matchRegexp);
  switch (type) {
    case Type.SELECT:
    case Type.ORDER:
      return re.test((valueField as Option)?.value as string);
    case Type.MULTISELECT:
      return (valueField as Option[] || []).map((item) => item.value).every((item) => re.test(item as string));
    default:
      return re.test(valueField as string);
  }
};

export const getConditions = (
  fdata: Record<string, OfferAttributes.ArgumentType>,
  values: OfferAttributes.ArgumentType,
): Record<string, boolean> => {
  if (!fdata) return {};

  return Object.entries(fdata).reduce((acc, [k, v]) => {
    const { useCondition } = v;
    const { field, value, matchRegexp } = getFormattedConditions(fdata, useCondition) || {};
    if (!field) return acc;
    const valueField = values[field];
    const element = fdata[field];
    const { type } = element;
    let examine = false;
    if (value) {
      examine = getExamineValueByType(type, valueField, value);
    }
    if (matchRegexp) {
      examine = getMatchRegexpByType(type, valueField, matchRegexp);
    }
    return { ...acc, [k]: examine };
  }, {});
};

const setGroupElement = (
  variable: string,
  acc: Record<string, ElementStruct>,
  item: [string, ElementStruct],
): Record<string, ElementStruct> => {
  const [k, v] = item;
  const {
    type, path, variable: variableElement, name,
  } = v;
  if (!acc?.[variable as string]) {
    return {
      ...acc,
      [variable]: {
        type,
        variable,
        elements: { [k]: v },
        options: [{ variable: variableElement, path, name }],
        path,
      },
    };
  }
  acc[variable as string].elements = { ...acc[variable as string].elements, [k]: v };
  acc[variable as string].options = [
    ...(acc[variable as string]?.options ?? []),
    { variable: variableElement, path, name },
  ];
  return acc;
};

export const getElements = (
  struct: Record<string, ElementStruct>,
): Record<string, ElementStruct> => {
  if (!struct) return {};
  return Object.entries(struct).reduce((acc: Record<string, ElementStruct>, item) => {
    const [k, v] = item;
    const { type, children } = v;
    if (type === Type.CATEGORY) {
      acc = setGroupElement('category', acc, item);
    }
    if (type === Type.GROUP) {
      acc = setGroupElement('group', acc, item);
    }
    if (children && Object.keys(children).length) {
      if (![Type.CATEGORY, Type.GROUP].includes(type as OfferAttributes.SupportedArgumentType)) {
        return {
          ...acc,
          [k]: {
            ...v,
            elements: isGroup(type as OfferAttributes.SupportedArgumentType) ? getElements(children) : children,
          },
        };
      }
      v.elements = [Type.CATEGORY, Type.GROUP].includes(type as OfferAttributes.SupportedArgumentType)
        ? getElements(children)
        : children;
    }
    if (!isGroup(type as OfferAttributes.SupportedArgumentType)) {
      return { ...acc, [k]: v };
    }
    return acc;
  }, {});
};

export const getValidationSchemaWithGroups = (
  fdata: Record<string, OfferAttributes.ArgumentType>,
  conditions: Record<string, boolean>,
) => {
  if (!fdata) return {};
  const filteredFdata = Object.entries(fdata).reduce((acc, [k, v]) => (
    conditions[k] === false ? acc : { acc, [k]: v }
  ), {});
  return getValidationSchema(filteredFdata);
};

export const getCategoryOptions = (
  conditions: Record<string, boolean>,
  options?: GroupOption[],
): GroupOption[] => {
  if (!options) return [];
  return options.reduce((acc: GroupOption[], item) => {
    const { path, variable, name } = item;
    const condition = conditions[path];
    return condition === false
      ? acc
      : [...acc, { variable, path, name }];
  }, []);
};

export const getMinMaxTypeValue = (
  type?: OfferAttributes.SupportedArgumentType,
  valueType?: OfferAttributes.SupportedValueType,
) => {
  if (!valueType) return getDefaultNameValue(type);
  return getNameValueByValueType(valueType);
};

export const getDefaulSelectValue = (defaultValue?: OfferAttributes.VariantValue[], options?: OfferAttributes.VariantValue[]) => {
  if (!defaultValue?.length) return undefined;
  const key = getDefaultNameValue(Type.SELECT, options);
  const value = key ? defaultValue[0][key] : '';
  return {
    value,
    label: value,
  };
};

export const getIsFloat = (valueType: OfferAttributes.SupportedValueType) => (
  [SupportedValueType.DOUBLE, SupportedValueType.FLOAT].includes(valueType)
);
