import clonedeep from 'lodash.clonedeep';
import { Migrator, Data } from './Migrator';

export type Meta<TVersions> = Record<string, Data<TVersions>>

export abstract class MigratorApp<TVersions> extends Migrator<TVersions> {
  protected abstract APP_NAME: string;
  protected abstract get latestVersion(): keyof TVersions & number;

  public migrateByApp(data?: Record<string, unknown>): Meta<TVersions> {
    if (!this.APP_NAME) throw new Error('APP_NAME required');

    const clonedData = data && typeof data === 'object' ? clonedeep(data) : {};

    if (!clonedData[this.APP_NAME] || typeof clonedData[this.APP_NAME] !== 'object') {
      clonedData[this.APP_NAME] = this.initializeData(data);
    }

    clonedData[this.APP_NAME] = this.migrate(clonedData[this.APP_NAME] as Data<TVersions>);
    return clonedData as Meta<TVersions>;
  }

  public prepareMeta<V extends keyof TVersions>(
    oldMeta: Record<string, unknown> | undefined,
    meta: TVersions[V],
  ): Meta<TVersions> {
    const version = this.latestVersion as V;

    return {
      ...oldMeta,
      [this.APP_NAME]: {
        version,
        data: this.validate(meta),
      },
    } as Meta<TVersions>;
  }

  public getData<V extends keyof TVersions>(meta?: Record<string, { data?: TVersions[V] }>) {
    return meta?.[this.APP_NAME]?.data;
  }

  public getMigratedAppData(data?: Record<string, unknown>) {
    const migrateAppData = this.migrateByApp(data);
    return this.getData(migrateAppData);
  }
}
