import * as Yup from 'yup';
import {
  OptionInfo, PriceType, SlotInfo, SlotUsage,
} from '@super-protocol/sdk-js';

export type Slot = {
  id?: string;
  info: SlotInfo;
  option: OptionInfo;
  usage: SlotUsage;
}

export type FileMetaV1 = {
  parameters?: string,
  url?: string;
  slots?: { path: string; slots: Slot[] }[];
};

export const getSlotValidationSchema = () => Yup.object().shape({
  id: Yup.string(),
  info: Yup.object({
    diskUsage: Yup.number().min(1).required(),
    cpuCores: Yup.number().default(0),
    gpuCores: Yup.number().default(0),
    ram: Yup.number().default(0),
    vram: Yup.number().default(0),
  }).default(undefined),
  option: Yup.object({
    bandwidth: Yup.number().default(0),
    externalPort: Yup.number().default(0),
    traffic: Yup.number().default(0),
  }).default(undefined),
  usage: Yup.object({
    priceType: Yup.mixed<PriceType>().oneOf(Object.values(PriceType)).required(),
    price: Yup.number().min(1).required(),
    maxTimeMinutes: Yup.number().default(0),
    minTimeMinutes: Yup.number().default(0),
  }).default(undefined),
});

export const FileMetaV1Schema: Yup.SchemaOf<FileMetaV1> = Yup.object({
  parameters: Yup.string(),
  url: Yup.string(),
  slots: Yup.array().of(
    Yup.object().shape({
      slots: Yup.array().of(getSlotValidationSchema()),
      path: Yup.string().required(),
    }),
  ).default(undefined),
}).default(undefined).optional();