import { createSlice } from '@reduxjs/toolkit';
import cloneDeep from 'lodash.clonedeep';
import { persistReducer } from 'redux-persist';
import type { PayloadAction } from '@reduxjs/toolkit';
import { getBroatcastActions } from 'lib/features/helpers';
import { getInitialState } from './helpers';
import {
  CreateOrderV2State,
  Model,
  Dataset,
  Compute,
  Engine,
  Steps,
  Process,
  ProcessType,
  ProcessValue,
  BuildOrderForm,
  CreatePassphraseForm,
  AdditionalFormContent,
  FieldsBuildOrderForm,
} from './types';
import { transform } from './persist';
import { Storage } from '../types';

const initialState: CreateOrderV2State = getInitialState();

export const NAME = 'createOrderV2';

export const createOrderV2 = createSlice({
  name: NAME,
  initialState,
  reducers: {
    reset() {
      return getInitialState();
    },
    updateProcesses(state, action: PayloadAction<Process>) {
      state.process = action.payload;
    },
    updateProcess(state, action: PayloadAction<{ process: ProcessType, value: ProcessValue }>) {
      const { process, value } = action.payload || {};
      state.process = { ...state.process, [process]: value };
    },
    resetBuildOrderFormFields(state, action: PayloadAction<{ reset?: FieldsBuildOrderForm[] }>) {
      const { reset } = action.payload || {};
      if (reset?.length) {
        const initialState = getInitialState();
        reset?.forEach((field) => {
          state.form[Steps.BUILD_ORDER][field] = initialState[field];
        });
      }
    },
    addModel(state, action: PayloadAction<{ value: Model | null; }>) {
      const { value } = action.payload || {};
      state.form[Steps.BUILD_ORDER].model = value;
    },
    deleteModel(state) {
      state.form[Steps.BUILD_ORDER].model = null;
    },
    addEngine(state, action: PayloadAction<{ value: Engine | null }>) {
      const { value } = action.payload || {};
      state.form[Steps.BUILD_ORDER].engine = value;
    },
    deleteEngine(state) {
      state.form[Steps.BUILD_ORDER].engine = null;
    },
    addCompute(state, action: PayloadAction<{ value: Compute | null }>) {
      const { value } = action.payload || {};
      state.form[Steps.BUILD_ORDER].compute = value;
    },
    deleteCompute(state) {
      state.form[Steps.BUILD_ORDER].compute = null;
    },
    addDataset(state, action: PayloadAction<{ value: Dataset | null }>) {
      const { value } = action.payload || {};
      if (!value) return;
      if (Array.isArray(state.form[Steps.BUILD_ORDER].datasets)) {
        state.form[Steps.BUILD_ORDER].datasets?.push(value);
      } else {
        state.form[Steps.BUILD_ORDER].datasets = [value];
      }
    },
    updateFormByStep(
      state,
      action: PayloadAction<{
         step: Steps.BUILD_ORDER | Steps.CONFIRM_TRANSACTIONS, values: BuildOrderForm | CreatePassphraseForm, exclude: string[]
      }>,
    ) {
      const { step, values, exclude = [] } = action.payload;
      if (JSON.stringify(values) !== JSON.stringify(state.form[step])) {
        const newValues = cloneDeep(values);
        exclude.forEach((key) => delete newValues[key]);
        (state.form as BuildOrderForm | CreatePassphraseForm)[step] = newValues;
      }
    },
    deleteDataset(state, action: PayloadAction<{ offerId?: string; fileId?: string; }>) {
      const { offerId, fileId } = action.payload || {};
      if (!offerId && !fileId) return;
      state.form[Steps.BUILD_ORDER].datasets = (state.form[Steps.BUILD_ORDER].datasets || []).filter((item) => {
        return (!offerId || item?.offerId !== offerId) && (!fileId || item?.fileId !== fileId);
      });
    },
    updateSubmitLoading(state, action: PayloadAction<boolean>) {
      state.submitLoading = action.payload;
    },
    addEngineConfiguration(state, action: PayloadAction<{ value?: Engine['configuration'] | null }>) {
      const { value } = action.payload || {};
      if (state.form[Steps.BUILD_ORDER].engine) {
        (state.form[Steps.BUILD_ORDER].engine as Engine).configuration = value;
      }
    },
    addEngineAdditonalFormContent(state, action: PayloadAction<{ value?: (AdditionalFormContent[]) | null }>) {
      const { value } = action.payload || {};
      if (state.form[Steps.BUILD_ORDER].engine) {
        (state.form[Steps.BUILD_ORDER].engine as Engine).additionalFormContent = value;
      }
    },
    goStep(state, action: PayloadAction<Steps>) {
      state.activeStep = action.payload;
    },
    updateLease(state, action: PayloadAction<number | null>) {
      state.form[Steps.BUILD_ORDER].lease = action.payload;
    },
  },
  selectors: {
    activeStepSelector: (state) => state.activeStep,
    formSelector: (state) => state.form,
    processSelector: (state) => state.process,
    submitLoadingSelector: (state) => state.submitLoading,
    leaseSelector: (state) => state.form[Steps.BUILD_ORDER].lease,
  },
});

export const {
  reset,
  addModel,
  addDataset,
  addCompute,
  addEngine,
  addEngineConfiguration,
  deleteCompute,
  deleteDataset,
  deleteEngine,
  deleteModel,
  updateProcesses,
  updateProcess,
  updateSubmitLoading,
  goStep,
  updateLease,
  updateFormByStep,
  addEngineAdditonalFormContent,
  resetBuildOrderFormFields,
} = createOrderV2.actions;

export const {
  activeStepSelector,
  formSelector,
  processSelector,
  submitLoadingSelector,
  leaseSelector,
} = createOrderV2.selectors;

export const getReducer = (storage: Storage) => persistReducer({
  key: NAME, storage, whitelist: ['form'], transforms: [transform],
}, createOrderV2.reducer);

export const getBroatcastWhiteList = () => getBroatcastActions(
  createOrderV2,
  ['goStep', 'updateFormByStep'].map((action) => `${NAME}/${action}`),
);